<template>
  <div class="star-send-container">
    <le-card width="100%" style="padding-bottom: 20px">
      <div class="card-title" >发放星光</div>
      <div class="clear-box">
        <div class="label">自动清空时间：</div>
        <div class="val">
          每周{{ clearObj.w | week }} {{ clearObj.h }}时{{ clearObj.i }}分
        </div>
        <le-button
          txt="编辑"
          width="60px"
          height="28px"
          fontSize="14px"
          @btnClick="clickEdit()"
          class="btn-edit"
        />

        <div class="label" >自动发放时间：</div>
        <div class="val">
          每周{{ sendObj.w | week }} {{ sendObj.h }}时{{ sendObj.i }}分
        </div>
        <le-button
          txt="编辑"
          width="60px"
          height="28px"
          fontSize="14px"
          @btnClick="clickSendEdit()"
          class="btn-edit btn-edit-bottom"
        />
        <le-button
          txt="立刻发放"
          width="80px"
          height="28px"
          fontSize="14px"
          @btnClick="auto_send()"
          class="btn-lk-send"
          background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"
        />
      </div>

      <div class="search-box">
        <div class="time-box">
          <div class="time-title">主播查询：</div>
          <a-input
            v-model="search"
            placeholder="请输入主播昵称或uid"
            :maxLength="20"
            width="200px"
            :allowClear="true"
            @change="changeSearch()"
            class="input-search"
          />

        <div class="button-box">
          <le-button 
          width="70px"
          fontSize="16px"
          
          @btnClick="searchClick()" />
        </div>
        </div>
      </div>

      <div class="table-box">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="loading"
          :pagination="pageOptions"
          @change="handleTableChange"
          size="middle"
        >
          <div slot="nameUid" slot-scope="row">
            <a
              class="name-box"
              :href="'https://space.bilibili.com/' + row.uid"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="avatar">
                <img :src="row.face_url" alt="" srcset="" />
              </div>
              <div class="name-uid">
                <div>{{ row.uname }}</div>
                <div>{{ row.uid }}</div>
              </div>
            </a>
          </div>
          <span
            slot="action"
            style="display: flex; justify-content: space-between"
            slot-scope="row"
          >
            <le-button
              txt="编辑余额"
              width="80px"
              height="30px"
              fontSize="14px"
              @btnClick="clickEditYe(row)"
            />
          </span>
        </a-table>
      </div>
    </le-card>

    <popEditTime
      v-if="visible"
      :visible="visible"
      :info="clearObj"
      @handleOk="editTimeOk()"
      @handleCancel="editTimeOk()"
    />
    <popEditSend
      v-if="visibleSend"
      :visible="visibleSend"
      :info="sendObj"
      @handleOk="editSendTimeOk()"
      @handleCancel="editSendTimeOk()"
    />
    <popEditYe
      :row="row"
      v-if="visibleYe"
      :visible="visibleYe"
      @handleOk="editYeOk()"
      @handleCancel="editYeOk()"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "工会",
    dataIndex: "group"
  },
  {
    title: "昵称/UID",
    scopedSlots: { customRender: "nameUid" },
  },
  {
    title: "上周新增",
    dataIndex: "week_new_anchor",
    sorter: (a, b) => a.week_new_anchor - b.week_new_anchor,
  },
  {
    title: "上周有效",
    dataIndex: "week_validlive",
    sorter: (a, b) => a.week_validlive - b.week_validlive,
  },
  {
    title: "本月新增",
    dataIndex: "new_anchor",
    sorter: (a, b) => a.new_anchor - b.new_anchor,
  },
  {
    title: "本月流水",
    dataIndex: "income",
    sorter: (a, b) => a.income - b.income,
  },
  {
    title: "应发",
    dataIndex: "need_starlight",
    sorter: (a, b) => a.need_starlight - b.need_starlight,
  },
  {
    title: "余额",
    dataIndex: "starlight",
    sorter: (a, b) => a.starlight - b.starlight,
  },
  {
    title: "操作",
    key: "action",
    width: 110,
    scopedSlots: { customRender: "action" },
  },
];
import { MixinList } from "@/common/mixin";
import { popEditTime, popEditSend, popEditYe } from "@/components/star/send";
import tool from '@/utils/tool'
export default {
  name: "star-send",
  components: {
    popEditTime,
    popEditSend,
    popEditYe,
  },
  data() {
    return {
      columns,
      dataUrl: "v1/user/starlight/list",
      delUrl: "admin/layout/del",
      visible: false,
      visibleSend: false,
      visibleYe: false,
      clearObj: {},
      sendObj: {},
      row: {},
      search:'',
      isKong:false
    };
  },
  computed: {},
  mixins: [MixinList],
  mounted() {
    // console.log("star", this.$route.query);
    this.get_clear_time(); //获取自动清空时间
    this.get_send_time(); //获取自动发放的时间
  },
  filters: {
    week(val) {
      if (!val) {
        return "--";
      }
      switch (val) {
        case 1:
          return "一";
          break;
        case 2:
          return "二";
          break;
        case 3:
          return "三";
          break;
        case 4:
          return "四";
          break;
        case 5:
          return "五";
          break;
        case 6:
          return "六";
          break;
        case 7:
          return "日";
          break;
      }
    },
  },
  methods: {
    searchClick(){
      console.log(this.search)
      if(!this.search){
        this.$message.error('请填入搜索条件');
        return false
      }
      let param={}
      if(this.search){
        this.queryParam.page = 1
        this.queryParam.search=this.search
        this.getData()
        // param.search=this.search
      }

      // tool.$emit('search', param)
    },
    changeSearch(){
      console.log(this.search)
      if(this.search){
        this.isKong=false
        return false;
      }

      if(this.isKong){
        return false;
      }
      this.isKong=true
      this.onClear()
    },
    editTimeOk() {
      this.visible = false;
      this.get_clear_time();
    },
    editSendTimeOk() {
      this.visibleSend = false;
      this.get_send_time();
    },

    editYeOk() {
      this.visibleYe = false;
      this.getData();
    },
    clickEdit() {
      this.visible = true;
    },

    clickSendEdit() {
      this.visibleSend = true;
    },

    clickEditYe(row) {
      this.row = row;
      this.visibleYe = true;
    },
    clickSend() {},
    auto_send() {
      this.$api.post(`v1/user/starlight/auto_send`, {}).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    get_clear_time() {
      this.$api.get(`v1/user/starlight/get_clear_time`).then((res) => {
        if (res.code == 200) {
          this.clearObj = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    get_send_time() {
      this.$api.get(`v1/user/starlight/get_send_time`).then((res) => {
        if (res.code == 200) {
          this.sendObj = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.star-send-container {
  width: 100%;
  .title {
    margin-left: 30px;
    padding-top: 20px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .clear-box {
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-top: 10px;
    .label {
      font-size: 16px;
      color: #333333;
    }
    .val {
      font-size: 14px;
      color: #008df0;
    }
    .btn-edit{
      margin-left: 20px;
    }
    .btn-lk-send{
      margin-left: 20px;
    }
  }
  .table-box {
    margin: 30px;
  }
}

.search-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .time-box {
    display: flex;
    align-items: center;
    .time-title {
      margin-left: 30px;
      font-size: 16px;
      color: #333333;
    }
    .input-search{
      width: 200px; 
      margin-left: 10px
    }
  }
  .button-box {
    margin-left: 30px;
  }
}

.name-box {
  display: flex;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@media screen and(max-width: 750px) {
.star-send-container {
  width: 100%;
  .clear-box {
    margin-left: 20px;
    margin-top: 10px;
    flex-wrap: wrap;
    .label {
      font-size: 14px;
      margin-left: 0px;
    }
    .val {
      font-size: 14px;
    }
    .btn-edit{
      margin-left: 5px;
    }
    .btn-edit-bottom{
      margin-top: 5px;
    }
    .btn-lk-send{
      margin-left: 0px;
    }
  }
  .table-box {
    margin: 20px;
    overflow-x: scroll;
  }
}

.search-box {
  margin-top: 20px;
  .time-box {
    .time-title {
      margin-left: 20px;
      font-size: 14px;
    }
    .input-search{
      width: 150px; 
      margin-left: 10px
    }
  }
  .button-box {
    margin-left: 20px;
  }
}

}

</style>
