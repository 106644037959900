<template>
  <div class="star-pop-edit-container">
    <a-modal
      title="自动清空时间"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="500px"
    >

    <div class="time-select">
      选择时间：
    <a-select :value="week" :default-value="week" style="width: 120px" @change="changeWk">
      <a-select-option :value="item.val" v-for="item in weeks" :key="item.val">
        {{item.name}}
      </a-select-option>
    </a-select>

      <a-time-picker :allowClear="false" v-if="times" :value="times" :default-open-value="times" @change="onChange" style="margin-left:10px">
        <a-icon slot="suffixIcon" type="down" />
      </a-time-picker>

    </div>
    </a-modal>
  </div>
</template>

<script>

import moment from 'moment'
export default {
  data() {
    return {
      confirmLoading: false,
      weeks:[
        {
          val:1,
          name:'每周一'
        },{
          val:2,
          name:'每周二'
        },{
          val:3,
          name:'每周三'
        },{
          val:4,
          name:'每周四'
        },{
          val:5,
          name:'每周五'
        },{
          val:6,
          name:'每周六'
        },{
          val:7,
          name:'每周日'
        }
      ],
      week:1,
      times:''
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  computed: {},
  mounted() {
    this.times= moment(this.info.h+':'+this.info.i+':'+this.info.s,'HH:mm:ss')
    this.week=this.info.w 
    console.log(this.times)
  },
  methods: {
    moment,
    changeWk(value){
      console.log(value);
      this.week=value
    },
    handleOk(e) {
      console.log(moment(this.times).format('HH'))
      // return false
      this.confirmLoading=true
      this.$api.post(`v1/user/starlight/set_clear`,{
        w:this.week,
        h:moment(this.times).format('HH'),
        i:moment(this.times).format('mm'),
        s:moment(this.times).format('ss')
      }).then(res=>{
        this.confirmLoading=false
        if(res.code==200){
          this.$message.success('设置成功')
          this.$emit("handleOk");
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleCancel(e) {
      this.$emit("handleCancel");
    },
    onChange(date, dateString) {
      console.log(date, dateString);
      this.times=moment(dateString,'HH:mm:ss')   
    }
  },
};
</script>

<style lang="less" scoped>
.star-pop-edit-container {

}

  .time-select{
    display: flex;
    align-items: center;
    font-size: 20px;
  }

</style>
